<template>
  <div class="user_member-box">
    <titleHeader :title="$route.meta.title"></titleHeader> 
   
    
    <van-card
      v-for="(a,index) in cpList" :key='index'
      
      :desc="a.specifications"
      :title="a.name"
      :thumb="a.cover"
    />
    <van-pagination v-model="currentPage" @change="changePagination" :page-count="pageCount" v-show="pageCount" mode="simple" />
  </div>
</template>

<script>
import {selectFavorite} from "../utils/api";
export default {
  components: {
    
  },
  data() {
    return {
      currentPage: 1,
      pageCount:null,
      total: 4,
      showShare:false,
      showNav:true,
      navShow:true,
      cpList: {},
      options : [
        { name: '微信', icon: 'wechat' },
        { name: '微博', icon: 'weibo' },
        { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'poster' },
        { name: '二维码', icon: 'qrcode' },
      ],

    }
    
  },
  
  created(){
    
    
  },
  mounted() {
    this.selectFavorite();
    
  },
  methods: {
    changePagination(val){
      
      this.currentPage=val;
      this.selectFavorite(this.currentPage);
    },
    selectFavorite(current){
      selectFavorite({
        current: current?current:'1',
      })
      .then((res) => {
        console.log(res);
        this.cpList=res.pageInfo.records;
        this.currentPage=res.pageInfo.current;
        this.pageCount=res.pageInfo.pages;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    onSelect(){
      this.showShare = false;
    },
    onClickLeft(){
      history.back();
    },
    onClickRight(){
      // this.$toast("点击图标");
      this.showShare = true;
    },
    goDetail(){
      this.$router.push("/detail");
    },
    
  },
  computed:{
    
  }
};
</script>

<style scoped lang="scss">
.user_member-box{
  background: #f7f7f7;
  min-height: 100vh;
  .user-header-box{
    padding-top: 80px;
    margin-bottom: 20px;
    height: 300px;
    background: #fcf;
    .user-header-content{
      text-align: center;
      margin:0px auto;
      .van-progress{
        margin:0px auto;
        width: 50%;
      }
      p{
        margin-top: 30px;
      }
    }
  }
  
  
}
</style>
